import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfLg from "../../assets/img/images/infinaeon.svg";

const WhoWeAre = (props) => {
  
  // Function to handle copying text
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text); // Copy text to clipboard
      toast.success('Copied to clipboard!', { position: "top-center", autoClose: 2000 });
    } catch (err) {
      toast.error('Failed to copy!', { position: "top-center", autoClose: 2000 });
    }
  };

  return (
    <section id="mainnet" className="about-area pb-30">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-img wow fadeInLeft" data-wow-delay=".5s">
              {/* Video or image content can go here */}
            </div>
          </div>
          <div className="col-lg-12 blur aboutbr">
            <div className="about-content wow fadeInRight" data-wow-delay="0.7s">
              <div className="section-title mb-30">
                <h2 className="maintitle">
                  Mainnet <span style={{ color: "#20B2A9" }}>Info</span>
                </h2>
              </div>
              <div className="aboutp2">
                <p className="firstinfo">
                  Add the Infinaeon chain (network) to your EVM wallet of choice (Trust Wallet, Safepal, Coinbase Wallet, Meta Mask, ...)
                </p>
                <div className="infostyle">
                  <p>Network Name: 
                    <span 
                      style={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleCopy('Infinaeon')}
                    > Infinaeon</span>
                  </p>
                  <p>RPC: 
                    <span 
                      style={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleCopy('https://rpc.infinaeon.com')}
                    > https://rpc.infinaeon.com</span>
                  </p>
                  <p>Symbol/Currency: 
                    <span 
                      style={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleCopy('ETH Infinaeon')}
                    > ETH</span>
                  </p>
                  <p>ChainId: 
                    <span 
                      style={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleCopy('420000')}
                    > 420000</span>
                  </p>
                  <p>Explorer: 
                    <span 
                      style={{ cursor: 'pointer', color: '#fff' }}
                      onClick={() => handleCopy('https://explorer.infinaeon.com')}
                    > https://explorer.infinaeon.com</span>
                  </p>
                </div>
                <p className="c2c">Click to copy the value</p>
                <p>(MetaMask users, if you get an error, try without https://)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default WhoWeAre;
