import React from "react"

const CtaButtons = () => {

    return (

        <>

<div class="row mb-20">
  <div class="col-sm-12 mt-3">
  <button class="buynowbtn firstcol pulsate-bck">

<a href="https://presale.infinaeon.com/">Buy Now</a>
                     
  </button>
  </div>
  {/* <div class="col-sm-6 mt-3">
  <button class="mintnftbtn secondcol pulsate-bck">
  <a href="https://mint.operationphoenix.network">Mint NFTs</a>
  </button>
  </div> */}
</div>

            {/* <div className="container">
            <div class="button-container">
  <button class="left-button buynowbtn firstcol">

<a href="https://presale.infinaeon.com/">Buy Now</a>
                     
  </button>
  <button class="right-button mintnftbtn secondcol">
  <a href="https://mint.operationphoenix.network">Mint NFTs</a>
  </button>
</div>

            </div> */}
            {/* </div> */}

        </>

    );
};

export default CtaButtons;