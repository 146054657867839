import React from "react";
import Countdown from "react-countdown";
import SolutionImg from "../../assets/img/images/solutionimg.jpg"
import PresaleMethods from "./PresaleMethods"
import sample2 from '../../assets/img/images/infcompressed.mp4#t=0.1';
import poster from '../../assets/img/images/infyt.jpg'
import cdvid from '../../assets/img/images/countdownbg.mp4#t=0.1';
import mainnet from "../../assets/img/images/mainnetlive6.png"
import CountDownMainnet from "../CountDown/CountDownMainnet";


const IntroVid = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hours
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minutes
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>seconds
          </div>
        </>
      );
    }
  };

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
              {/* <h2 className="title2">Problem Statement:</h2> */}
              <div id="countdown-gampang"></div>
              <div className="custom-countdown-two">
              <div className="video-description">
	{/* <img className="img-responsive" width="180" height="40" src="logo.png" alt="Codeconvey" scale="0" /> */}
  <CountDownMainnet />
   </div>


   <img src={mainnet} className="mainnetimg" alt="Mainnet Timer"/>
                {/* <video className="about-vid problemimg" loop autoPlay muted playsInline >

                  <source src={cdvid} type='video/mp4' />
                </video> */}
                <PresaleMethods />
                {/* <div className="custom-countdown-two">
                <Countdown date={Date.now() + 5000000} renderer={renderer} />
                 </div> */}
                {/* <div className="inlinebtn">
                    <button style={{ marginRight: "20px" }} className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div> */}
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>
  );
};

export default IntroVid;
