import React from "react";
import logoTxt from "../../assets/img/images/logowtxt.png"

import presaleImg from "../../assets/img/images/infpresale.jpg"
// import shape02 from "../../assets/img/banner/banner_shape02.png";
// import shape03 from "../../assets/img/banner/banner_shape03.png";
// import fireIcon from "../../assets/img/icon/fire.png";
// import CountDownOne from "../CountDown/CountDownOne";
// import '@vidstack/react/player/styles/default/theme.css';
// import '@vidstack/react/player/styles/default/layouts/audio.css';
// import '@vidstack/react/player/styles/default/layouts/video.css';
// import InfVideo from "../../assets/img/images/infloop.mp4"
import StickyBg from "../../assets/img/images/infwebbg.jpg";
// import StickyBg2 from "../../assets/img/images/sprinkle2.svg";
import StarVid from "../../assets/img/images/spacebgteal.mp4"
import Countdown from "../CountDown/CountDownTwo";

import sample2 from '../../assets/img/images/infvid.mp4#t=0.1';
import poster from '../../assets/img/images/infyt.jpg'

const BannerThree = (props) => {
  return (
    <section className="banner-area banner-bg2 ">
      {/* <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div> */}
      {/* <div class="vidwrap">
        <video id="hero-vid" autoPlay={" "} loop={" "} muted={" "} playsInLine={" "} src={InfVideo} type={"video/mp4"}></video>
    </div> */}
      {/* <div class="vidwrap">
        <img src={StickyBg} id="hero-vid" />    </div> */}

    

        {/* <div class="vidwrap">
        <video id="hero-vid2" autoPlay={" "} loop={" "} muted={" "} playsInLine={" "} src={StarVid} type={"video/mp4"}></video>
    </div> */}


        {/* <img src={StickyBg2} id="hero-vid" /> 
   </div> */}

        {/* <div className="bg-3"></div> */}
    
      <div className="container valign">
        <div className="marginb">
          <div className="row justify-content-center">
            <div className="col-lg-12">

              <div className="banner-content text-center">

                <div className="row2">

                  {/* <img src={fireIcon} alt="" /> */}
                  <img src={logoTxt} alt="" className="lgimg" />
                </div>

                {/*               
              <br />



              <img src={presaleImg} alt="" className="lgimg2" />
              <div className="lgimg3">
              <button><a href="https://presale.infinaeon.com/">Presale is now live!</a></button> 

              </div> */}
                {/* <div className="spacer"></div> */}


                <div className="row">
                  {/* <div className="inlinebtn">
                    <button style={{ marginRight: "20px" }} className="presalebtn"><a href="https://presale.infinaeon.com/">Join the presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div> */}
                  <div className="spacer"></div>
                  {/* <Countdown /> */}
                    <div className="inlinebtn" style={{ marginTop: "20px" }}>
                  {/* <div className="inlinebtn" style={{ marginTop: "-40px" }}> */}
                    <button style={{ marginRight: "20px" }} className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div>

                  {/* <video loading="lazy" className="about-vid" controls muted playsInline poster={poster} >

                    <source src={sample2} type='video/mp4' />
                  </video> */}
                </div>

                <div className="row">

                  {/* <div className="centerpiece">
                  <div className="presalebtn">
                    <button><a href="https://presale.infinaeon.com/">Join the presale</a></button>
                  </div>
                  <div className="mint">
                    <button><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>
                  </div>
                </div> */}
                  {/* <div className="spacer"></div> */}
                  {/* <Countdown /> */}
                  {/* <div className="centerpiece">
                    <div className="presalebtn">
                      <button><a href="https://presale.infinaeon.com/">Join the presale</a></button>
                    </div>
                    <div className="mint">
                      <button><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>
                    </div>
                  </div> */}
                </div>
              </div>


            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">ICO Will Start In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default BannerThree;
