import React from "react"
import Arbitrum from "../../assets/img/presaleicons/arbitrum.svg"
import Ethereum from "../../assets/img/presaleicons/ethereum.webp"
import Bsc from "../../assets/img/presaleicons/bsc.webp"
import Polygon from "../../assets/img/presaleicons/polygon.webp"
import Base from "../../assets/img/presaleicons/base.webp"
import CardIcon from "../../assets/img/presaleicons/card.svg"
import CtaButtons2 from "./CtaButtons4"

const CtaButtons = () => { 

    return (

        <>
        
        <div className="container">
          
              {/* <div className="inlinebtn">
              <div className="spacer nospacer"></div>
                    <button  className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div>  */}
<a href="https://presale.infinaeon.com/">
<div class="btn-group pigroup gap-0" role="group" aria-label="Basic example">

<button type="button" class="presalelive">Presale is live</button>



  <button type="button" class=" "><img src={Ethereum} /></button>
  <button type="button" class=" "><img src={Bsc} /></button>
  <button type="button" class=" "><img src={Arbitrum} /></button>
  <button type="button" class=" "><img src={Base} /></button>
  <button type="button" class=" "><img src={Polygon} /></button>

 
  <button type="button" class=" "><img src={CardIcon} /></button>
 
  {/* <button type="button" class=" ">Right</button> */}
</div>
</a>
<br></br>
<CtaButtons2 />

        </div>
        
        </>

    );
};

export default CtaButtons;