import React from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Market Research & Feasibility Study",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Concept",
      info: <><b>Identify the Problem:</b> Clearly define the problem your crypto project aims to solve (e.g., scalability, privacy, interoperability).- Research Competitors: Analyze existing crypto solutions to understand the competitive landscape.
     
   
     </>,
    info2: <> <b>Target Audience:</b> Define your primary users (investors, traders, businesses, developers, etc.) and their pain points.
</>  ,

info3: <>
 <b>Feasibility Study:</b> Determine the technical and economic feasibility of your project.
</>

  
  },
    {
      roadmapTitle: "Whitepaper Creation",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "App beta test",
      info: (
        <>
    <b>Draft Whitepaper:</b> A comprehensive document detailing your project's mission, technology, tokenomics, use cases, and roadmap.
        </>
      ),

      info2: (
        <>
<b>Legal and Regulatory Review:</b> Ensure the project complies with the necessary regulations and legal frameworks for token issuance and operation in key markets.        </>
      ),
    },
    {
      roadmapTitle: "Community Building ",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",
      // title: "Alpha Test",
      info: (
        <>
        <b>Social Media Presence: </b> Set up Twitter, Telegram, Discord, and other channels for communication.
        </>
      ),
      info2: (
        <>
<b>Community Engagement: </b>C Start engaging with the crypto community, answering questions, and creating educational content.        </>
      ),
      info3: (
        <>
<b>Influencer Partnerships:</b> Partner with crypto influencers to raise awareness.        </>
      ),
    },
    {
      roadmapTitle: "Launch on BSC and Build out Ecosystem",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Operational",
      // checkmark: "../../assets/img/images/checkmark.svg",
      info: <>Token launch to 110X</>,
      info2: <>DEX</>,
      info3: <>Sell-Less Swapper</>,
      info4: <>Auto-Compound Staking</>,
      info5: <>Launchpad</>,
      info6: <>Merch</>,
    
    },
    {
      roadmapTitle: "Presale Creation",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Operational",
      info: <>2 Million Goal</>,
      info2: <>850K Towards Goal</>,
      info3: <>Current 50% Bonus on Presale (Via Promo Code)</>,
 
    },
    {
      roadmapTitle: "Marketing Campaigns",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Operational",
      info: <><b>Paid Ads:</b> Run targeted ads on crypto-related websites, Instagram Marketing, Facebook Marketing, and Programmatic Ads</>,
      info2: <><b>Content Marketing:</b> Release blog posts, podcasts, video tutorials, and webinars to educate users about your project.</>,
      info3: <><b>Influencer Campaigns:</b> Scale influencer campaigns to reach a wider audience and partner with mainstream Influencers </>,
      
    },
    {
      roadmapTitle: "Blockchain Development",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Operational",
      info: <><b>Platform Selection:</b> Choose between building our own blockchain or using existing platforms like Ethereum, Binance Smart Chain, or Layer 2 solutions.</>,
      info2: <><b>Test Net:</b> Live for the past 2 months.</>,
      info3: <><b>MainNet:</b> Blockchain is live and on MainNet</>,
    
    },
    {
      roadmapTitle: "DEX (Decentralized Exchange)",
      checkmark: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='#00bbff'><path d='M20.285 2.591l-11.764 11.781-4.571-4.57-2.286 2.285 6.857 6.856 14.051-14.072z'/></svg>",

      // title: "Operational",
      info: <>The Decentralized Exchange allows you to launch and trade on the Infinaeon Blockchain</>,
     
    },
    {
      roadmapTitle: "Lunar Pump Fun (LPF)",
      // title: "Operational",
      info: <>LPF will serve as one of the flagship in-house utilities, designed to cover the top EVM chains and provide a platform for a broad range of users.</>,
      info2: <><b>User-Focused:</b> The platform will be highly user-friendly.</>,
      info3: <><b>Progress:</b> Development work on LPF is already underway.</>,
     
    },
    {
      roadmapTitle: "Listings and Integrations",
      // title: "Operational",
      info: <><b>CEX Listings:</b> Initially, listings for the Infinaeon native token will focus on the Ethereum network. As the community and reach expand, these listings will transition to the Infinaeon mainnet.</>,
      info2: <><b>Mainnet Integrations:</b> High-priority work is underway to implement integrations for: Buy Bots, Token Scanners, Volume Bots, Dex Screeners, Analytics Platforms, Wallets, and Exchanges.</>,
     
    },
  ];



  return (
    <section id="roadmap" className="roadmap-area-two pt-130 pb-20">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title section-title-two text-center mb-65">
              <span className="maintitle">roadmap</span>
              {/* <h2 className="title">
                Bigtech Strategy and <br />
                Project Plan
              </h2> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {/* <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div> */}

<div className="roadmap-wrap-two">
  {roadmap_items.slice(0, 4).map((x, index) => (
    <RoadmapTwoItem key={index} item={x} />
  ))}
</div>

            <br>
            </br>
            {/* <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={4} />
              ))}
            </div> */}
            <div className="roadmap-wrap-two">
  {roadmap_items.slice(4, 8).map((x, index) => (
    <RoadmapTwoItem key={index} item={x} />
  ))}
</div>
 <br></br>
<div className="roadmap-wrap-two mauto">
 
  {roadmap_items.slice(8, 12).map((x, index) => (
    <RoadmapTwoItem key={index} item={x} />
  ))}
 
</div>



            {/* <div className="roadmap-wrap-two bottom">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;
