import React from "react";
// import img01 from "../../assets/img/partner/partner_img01.png";
// import img02 from "../../assets/img/partner/partner_img02.png";
// import img03 from "../../assets/img/partner/partner_img03.png";
// import img04 from "../../assets/img/partner/partner_img04.png";
// import img05 from "../../assets/img/partner/partner_img05.png";
// import img06 from "../../assets/img/partner/partner_img06.png";
// import img07 from "../../assets/img/partner/partner_img07.png";
// import img08 from "../../assets/img/partner/partner_img08.png";
// import img09 from "../../assets/img/partner/partner_img09.png";
// import img10 from "../../assets/img/partner/partner_img10.png";


import icon01 from "../../assets/img/images/infinaeon-black.svg";
import icon02 from "../../assets/img/images/infinityswap.svg";
import icon03 from "../../assets/img/images/fpump.svg";
import icon04 from "../../assets/img/images/2.svg";
import icon05 from "../../assets/img/images/stake.svg";
import icon06 from "../../assets/img/images/drop.svg";
import WhyChooseUsItem from "../WhyChooseUs/WhyChooseUsItem";
import Presale from "../../assets/img/images/presale.png"

const featuresList = [
  {
    src: icon01,
    alt: "",
    
    title: "Infinaeon",
    title2: "Native Token",
    description: "This dynamic native token will be the main benefactor within the ecosystem. It can be purchased during Presale and will help bring recognition while also assuring the long term viability of the blockchain itself. \n",
    description2: "The deflationary mechanism ensures that Infinaeon coins are consistently taken out of circulation, leading to sustainable rewards without any inflationary pressures.",
    description3: "50% all Gas & DEX fees on the chain will be automatically kicked back to the token to buy and burn. \n",
    description4: "All future platforms built on the Blockchain (Launchpad, NFT Marketplace, etc) will also buy and burn. \n",
    description5: "The Native Token is the token that will be held as an Investment as it is the token that will have the main LP attached and listed on all exchanges.",
  },

  {
    src: icon06,
    alt: "",
    
    title: "Infinaeon Plus",
    title2: "Liquidity Token",
    description: "A portion of gas fees is allocated to the Infinaeon Plus contract, a wrapped ETH token on the chain. This allows Infinaeon Plus to appreciate in value. \n",
    description2: "Infinaeon Plus starts at a 1:1 value with Ethereum, but with each transaction, ETH is added to its contract, increasing its value over time. \n",
    description3: "This mechanism ensures that all tokens paired with Infinaeon Plus benefit from this appreciation, creating a positive feedback loop of value increase. \n",
    description4: "Tokens that launch on the chain will pair with Infinaeon Plus if they want the benefit of the value of their underlying asset increasing over time. \n"
 
  },

  {
    src: icon03,
    alt: "",
    
    title: "Infinaeon Gas Token",
    title2: "Gas Token",
    description: "1:1 Value with Ethereum \n",
    description2: "Will work very similar to Base and Arbitrum (ETH) \n",
    description3: "Will be the token used to interact on the Blockchain and will always be a 1:1 Value with Ethereum \n",
    description4: "There will also be a bridge to easily switch between Ethereum and Infinaeon Ethereum. \n"
 
  },


  {
    src: icon04,
    alt: "",
  
    title: "Bridge",
    description: "The bridge mechanism facilitates seamless conversion between ETH, Base, BNB, and Arbitrum. \n",
    description2: "Bidirectional Conversion: Enables users to switch between ETH, Base, BNB, and Arbitrum tokens effortlessly. \n",
    description3: "Efficiency: Minimizes friction in asset transfers, promoting fluidity and usability within the ecosystem. \n",
    description4: "Revenue Sharing: Revenue generated from the bridge fees will be allocated back to the revenue sharing.",

  },
  
 
  {
    src: icon02,
    alt: "",
    
    // title: <>Infinity Swap <br />(The Native DEX)</>,
    title: 'Infinity Swap',
    title2: 'The Native DEX',
    description: "Infinity Swap will feature Infinaeon Plus as its primary wrapped ETH asset, with most tokens defaulting to pair with it. \n",
    description2: "Projects listing on Infinity Swap will experience value appreciation in real-time as their backing asset, Infinaeon Plus, rises in value with each transaction. This structure ensures that every transaction on the chain boosts the value of all tokens on Infinity Swap, creating a robust and dynamic ecosystem.",

  },

  {
    src: icon05,
    alt: "",

    title: "Auto Compound Staking",
    description: "Infinaeon offers a compelling staking mechanism to incentivize long-term participation \n",
    description2: "Auto-Compounding Rewards: Tokens staked for 6 months will receive a competitive 10% (APY) ,compounded automatically. The pool will also receive 5% of all gas fees generated on the chain. \n",
    description3: "Revenue Funded: Unlike majority of tokens in existence the staking is funded by revenue and not gifted supply or taxed money. \n",
    description4: "Penalty Mechanism: Unstaking before the period ends incurs a 30% penalty, encouraging commitment and stability.",
  },


];



const TopPartners = () => {
  // const features = [
  //   { src: icon01, description},
 
  // ];

  return (
    <div className="partner-area pb-130 partnerbg " id="ecosystem">
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            
          </div>
        </div>
        <div className="row">
        <div className="container ">
          <div className="col-lg-12">
          <div className="section-title text-center mb-20">
            {/* <h2 className="ecotitle">
              Our  <span className="text-grad" >Ecosystem</span>
              </h2> */}
                {/* <h2 className="ecotitle">
              Our Ecosystem</h2> */}
              <h2 className="maintitle"> 
  {/* <img src={InfLg} className="inflg" /> <br></br> */}

              Our <span style={{color: "#20B2A9"}}>Ecosystem</span>
              </h2>
            </div>
          <div className="partner-wrap">
              <ul>
                
                {featuresList.map((item, index) => (
                  // <li key={index}>
                  //   <img src={item.src} alt={item.title} />
                  // </li>
                   <div key={index} className="col-lg-12 md-12 sm-12  " >
                   <WhyChooseUsItem item={item}
                   />
                        
      
   
              
                 </div>

                  
                ))}
              </ul>
              {/* <img  className="presaleimg" src={Presale} alt="presale" /> */}

                          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default TopPartners;
