import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfLg from "../../assets/img/images/infinaeon.svg";

const WhoWeAre = (props) => {
  
  // Function to handle copying text
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text); // Copy text to clipboard
      toast.success('Copied to clipboard!', { position: "top-center", autoClose: 2000 });
    } catch (err) {
      toast.error('Failed to copy!', { position: "top-center", autoClose: 2000 });
    }
  };

  return (
    <section id="tokenomics" className="about-area pb-30">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-img wow fadeInLeft" data-wow-delay=".5s">
              {/* Video or image content can go here */}
            </div>
          </div>
          <div className="col-lg-12 blur aboutbr">
            <div className="about-content wow fadeInRight" data-wow-delay="0.7s">
              <div className="section-title mb-30">
                <h2 className="maintitle">
                 Tokenomics
                </h2>
              </div>
              <div className="aboutp2">
                {/* <p className="firstinfo">
                  Add the Infinaeon chain (network) to your EVM wallet of choice (Trust Wallet, Safepal, Coinbase Wallet, Meta Mask, ...)
                </p> */}
                <div className="infostyle">
                  <p>Existing Holders:
                    <span 
                   
                    >  91,601,761 </span>
                  </p>
                  <p>Presale Holders: 
                    <span 
                   
                    > 81,871,345</span>
                  </p>
                  <p>CEX, Marketing, Giveaways: 
                    <span 
                     
                    >  246,526,893</span>
                  </p>

                  <p>Total Supply: 
                    <span 
                     
                    >  420,000,000</span>
                  </p>
                 
                </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default WhoWeAre;
