import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/images/infinaeon.svg";
import { scrollToTop } from "../../lib/helpers";
import WhitePaper from "../../assets/files/whitepaper.pdf"

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="Infinaeon Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                    Infinaeon: Beyond Limits, Beyond Time.
                    </p>
                    <ul className="footer-social">
                    <li>
                    <a target="_blank"  href="https://t.me/Infinaeon">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                      <li>
                      <a target="_blank"  href="https://x.com/Infinaeon">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    
                      <li>
                      <a target="_blank"  href="https://discord.gg/infinaeon">
                          <i className="fab fa-discord"></i>
                        </a>
                      </li>
                      <li>
                      <a target="_blank"  href="https://www.youtube.com/@Infinaeon">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  {/* <h4 className="fw-title text-grad">Useful Links</h4> */}

                  <h2 className="fw-title">
              Useful<span className="text-grad" id="ecosystem"> Links</span>
              </h2>  
                  <div className="footer-link">
                    <ul>
                  
                    <li  className="highlightfooter" >
                      <a  target="_blank"  href="https://presale.infinaeon.com/">
                         Presale
                        </a>
                      </li>
                      <li  className="highlightfooter" >
                      <a  target="_blank"  href="https://infinityswap.finance/">
                         DEX
                        </a>
                      </li>

                      <li  className="highlightfooter2">
                      <a target="_blank"  href="https://bridge.infinaeon.com">
                          Bridge
                        </a>
                      </li>
                      <li  className="highlightfooter2">
                      <a target="_blank"  href="https://explorer.infinaeon.com">
                          Explorer
                        </a>
                      </li>
                      
                    {/* <li>
                      <a href="https://swap.infinityswap.finance/">
                          Infinity Swap
                        </a>
                      </li> */}
                
                      {/* <li>
                      <a href="#">
                          Staking
                        </a>
                      </li> */}
                      <li>
                       <a  target="_blank"  href="https://assuredefi.com/projects/infinaeon">
                          KYC
                        </a>
                      </li>

                
                    
                      <li>
                       <a  target="_blank"  href="https://infinaeon.com/whitepaper.pdf" >
                          White Paper
                        </a>
                      </li>
                   
                    
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  {/* <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Help Center</Link>
                      </li>
                      <li>
                        <Link to="/">Partners</Link>
                      </li>
                      <li>
                        <Link to="/">Suggestions</Link>
                      </li>
                      <li>
                        <Link to="/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/">Newsletters</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                {/* <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl aliquip
                      ex ea commodo
                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="Info@gmail.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2025 Infinaeon. All Rights Reserved </p>
                </div>
              </div>
              {/* <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="#">Login / Signup</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
