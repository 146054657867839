import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/images/inphinaeon.png";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import WhitePaper from "../../assets/files/whitepaper.pdf"

const HeaderOne = () => {
  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler"}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img src={logoImage} alt="Infinaeon Logo" className="logoimg" />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          hash == "" && "active",
                          "menu-item-has-children"
                        )}
                      >
                        {/* <Link
                          to="#header"
                          className={"section-link"}
                          onClick={() => handleClickScroll("header")}
                        >
                          Home
                        </Link>
                        <ul className={cn("sub-menu")}>
                          <li className={cn(pathname == "/" && "active")}>
                            <NavLink to="/">Home One</NavLink>
                          </li>
                          <li
                            className={cn(pathname == "/home-two" && "active")}
                          >
                            <NavLink to="/home-two">Home Two</NavLink>
                          </li>
                        </ul> */}
                      </li>
                      <li className={cn(hash == "#mainnet" && "active")}>

                        <Link
                          to="#mainnet"
                          className={"section-link"}
                          onClick={() => handleClickScroll("mainnet")}
                        >
                          Mainnet
                        </Link>
                      </li>


                      <li>
                        <Link
                          to="#about"
                          className={"section-link"}
                          onClick={() => handleClickScroll("about")}
                        >
                          About us
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="#ecosystem"
                          className={"section-link"}
                          onClick={() => handleClickScroll("ecosystem")}
                        >
                          Ecosystem
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#roadmap"
                          className={"section-link"}
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          Roadmap
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#tokenomics"
                          className={"section-link"}
                          onClick={() => handleClickScroll("tokenomics")}
                        >
                          Tokenomics
                        </Link>
                      </li>

                      <li>
                        <a className="highlight" target="_blank" href="https://presale.infinaeon.com/">
                          Presale
                        </a>
                      </li>
                      {/* <li>
                      <a target="_blank"  className="highlight2" href="https://mint.operationphoenix.network">
                         Mint NFTs
                        </a>
                      </li> */}
                      <li>
                        <a target="_blank" href="https://www.cryptolids.com/infinaeon">
                          Merch
                        </a>
                      </li>


                      {/* <li>
                      <a href="https://swap.infinityswap.finance/">
                          Infinity Swap
                        </a>
                      </li> */}
                      
                        <li>
                      <a  target="_blank"  href="https://infinityswap.finance">
                          DEX
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://bridge.infinaeon.com">
                          Bridge
                        </a>
                      </li>
                      <li>


                        <a target="_blank" href="https://explorer.infinaeon.com">
                          Explorer
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://assuredefi.com/projects/infinaeon">
                          KYC
                        </a>
                      </li>

                      <li>
                        <a target="_blank" href="https://infinaeon.com/whitepaper.pdf" >
                          White Paper
                        </a>
                      </li>
                      {/* <li>
                      <a href="#">
                         Staking
                        </a>
                      </li> */}



                      {/* <li className={"menu-item-has-children"}>
                        <Link to="/blog">Blog</Link>
                        <ul className={cn("sub-menu")}>
                          <li className={cn(pathname == "/blog" && "active")}>
                            <Link to="/blog">Our Blog</Link>
                          </li>
                          <li
                            className={cn(
                              pathname == "/blog-details" && "active"
                            )}
                          >
                            <Link to="/blog-details">Blog Details</Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className={isActiveLink("#contact")}>
                        <Link
                          to="#contact"
                          className={"section-link"}
                          onClick={() => handleClickScroll("contact")}
                        >
                          Contact us
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul>
                      {/* <li className={"header-lang"}>
                        <span className={"selected-lang"}>ENG</span>
                        <ul className={"lang-list"}>
                          <li>
                            <Link to="#">IND</Link>
                          </li>
                          <li>
                            <Link to="#">BNG</Link>
                          </li>
                          <li>
                            <Link to="#">TUR</Link>
                          </li>
                          <li>
                            <Link to="#">CIN</Link>
                          </li>
                        </ul>
                      </li> */}
                      <li className={"header-btn"}>
                        <Link to="https://presale.infinaeon.com/" className="btn">
                          Presale
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="Infinaeon Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                  </div>

                  <div className={"social-links"}>
                    <ul className="clearfix">
                      <li>
                        <a href="https://t.me/Infinaeon">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/Infinaeon">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://discord.gg/infinaeon">
                          <i className="fab fa-discord"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li> */}
                      <li>
                        <a href="https://www.youtube.com/@Infinaeon">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* 
 */}
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
