import React from "react";

const RoadmapTwoItem = (props) => {
  return (
    <div className="roadmap-item">
      <span className="roadmap-title">{props.item.roadmapTitle}  <span dangerouslySetInnerHTML={{ __html: props.item.checkmark }} /></span>
      <div className="roadmap-content">
        <span className="dot"></span>
        <h4 className="title">{props.item.title} </h4>
       

        <p>{props.item.info}</p> <br /> 
        <p>{props.item.info2}</p> <br /> 
        <p>{props.item.info3}</p> <br /> 
        <p>{props.item.info4}</p> <br /> 
        <p>{props.item.info5}</p> <br /> 
        <p>{props.item.info6}</p> <br /> 
        <p>{props.item.info7}</p>
      </div>
    </div>
  );
};

export default RoadmapTwoItem;
